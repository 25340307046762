import { CheckOldWebSiteUrl, GetLang } from '@/lib/lib-front';
export default defineNuxtRouteMiddleware((to, from) => {
  // 過去WebSiteの各ページからのリダイレクト
  // console.log('to.path', to.path, from);
  // const fromLang = GetLang(from.path);
  const toLang = GetLang(to.path);
  const redirect = CheckOldWebSiteUrl(to.path);
  if (redirect !== null) {
    // 2023-06-01以前の旧サイト用ページのリダイレクト
    return navigateTo(redirect);
  }
  if (to.path === '/sitemap.xml') {
    // サイトマップのリダイレクト
    return navigateTo('/api/sitemap.xml');
  }
  if (to.path === '/' || toLang === null) {
    // 言語設定が指定されていない場合のリダイレクト
    return navigateTo('/ja/');
  }
});
